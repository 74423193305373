import React from "react"
import { Link } from "gatsby"
import DefaultLayout from "../layouts/DefaultLayout"
import { Wrapper } from "../components/Layout"

const NotFoundPage = () => (
  <DefaultLayout>
    <main id="not-found-page">
      <Wrapper size="l" className="py-20 mx-auto">
        <Link to="/">
          Go back
        </Link>

        <Wrapper size="m">
          <h1 className="my-20 text-6xl">
            Page Not Found
          </h1>

          <div>
            <p>You just hit a route that doesn&#39;t exist..</p>
          </div>
        </Wrapper>
      </Wrapper>
    </main>
  </DefaultLayout>
)

export default NotFoundPage
