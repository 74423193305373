// import React, { useState, useGlobal } from "reactn"
import React, { useState } from "react"
import styled from "styled-components"
// import { navigate } from "gatsby-link"
// import palette from "../design/palette"
// import GdprDialog from "./GdprDialog"

// const InputIcon = styled(Icon)`color: rgba(0, 0, 0, .25);`

// const NewsletterInput = styled(Input)`
//   input {
//     border-right: 0 !important;
//     background-color: transparent !important;
//     padding-left: 36px !important;
//   }
// `

// const NewsletterButton = styled(Button)`
// `

const encode = (data) => {
  return Object.keys(data)
    .filter((key) => !!data[key])
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Input = styled.input`
`

const BefaringForm = (props) => {
  const [ showGdprDialog, setShowGdprDialog ] = useState(false)
  // const { 1: setShowJoinTheCauseModal } = useGlobal("showJoinTheCauseModal")

  const openGdprDialog = () => {
    props.form.validateFields((error, values) => {
      if(error) {
        return notification.error({
          message: "Validation error!",
          description: `Vennligst fyll inn alle feltene.`
        })
      }
      setShowGdprDialog(true)
    })
  }
  const closeGdprDialog = () => {
    setShowGdprDialog(false)
  }
  const acceptGdprDialog = () => {
    handleSubmit()
    closeGdprDialog()
  }
  const denyGdprDialog = () => {
    closeGdprDialog()
  }
  const handleSubmit = async () => {
    const values = {}

    try {
      await fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode({
          "form-name": "befaring",
          ...values
        })
      })

      // notification.success({
      //   message: "Suksess",
      //   description: "Din befaring er nå booket"
      // })
      // setShowJoinTheCauseModal(false)
      // navigate("/thanks")
    } catch(e) {
      console.error(e)
      // notification.error({
      //   message: "Ops, noe gikk galt!",
      //   description: e.message
      // })
    }
  }

  return (
    <form
      className="flex"
      name="befaring"
      action="/thanks"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="befaring" />
      <input type="hidden" name="bot-field" />

      <Input
        className="flex-1 border border-solid border-black bg-white rounded-sm mr-2 px-3"
        name="name"
        placeholder="Skriv inn ditt navn"
      />
      <Input
        className="flex-1 border border-solid border-black bg-white rounded-sm mx-2 px-3"
        name="adresse"
        placeholder="Skriv inn din adresse"
      />
      <Input
        className="flex-1 border border-solid border-black bg-white rounded-sm mx-2 px-3"
        name="tidspunkt"
        placeholder="Skriv et tidspunkt"
      />
      <select
        className="flex-1 border border-solid border-black bg-white rounded-sm mx-2 px-3"
        name="loftstype"
      >
        <option disabled selected>Velg din loftstype</option>
        <option>Råloft</option>
        <option>Miniloft</option>
        <option>Penthouse</option>
      </select>

      <button
        onClick={openGdprDialog}
        className="bg-black text-white px-4 py-3 font-semibold ml-2"
      >
        Book en befaring
      </button>

      {/* <GdprDialog
        shown={showGdprDialog}
        onAccept={acceptGdprDialog}
        onDeny={denyGdprDialog}
      /> */}
    </form>
  )
}

export default BefaringForm
