import React from "react"
import { Link } from "gatsby"

const PageHeader = () => (
  <header className="py-4 text-center fixed inset-x-0 top-0 z-10 bg-white">
    <div className="text-4xl font-bold tracking-widest leading-none mb-2">
      YMRO
    </div>

    <nav>
      <Link className="mr-6" to="/#om-selskapet">Om Selskapet</Link>
      <Link className="mx-6" to="/#ansatte">Ansatte</Link>
      <Link className="mx-6" to="/#prosjekter">Prosjekter</Link>
      <Link className="mx-6" to="/#tjenester">Tjenester</Link>
      <Link className="ml-6" to="/#kontakt-oss">Kontakt Oss</Link>
    </nav>
  </header>
)

export default PageHeader
