import React from "react"
import Img, { FluidObject } from "gatsby-image"

export interface ImageProps {
  src: string | {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  alt?: string
  style?: object
  className?: string
  critical?: boolean
  fadeIn?: boolean
}

const Image: React.FC<ImageProps> = (props) => {
  let img = null

  if(typeof props.src === "string") {
    img = <img {...props} src={props.src} />
  } else {
    img = <Img
      fluid={props.src.childImageSharp.fluid}
      {...props}
    />
  }

  return img
}

export default Image
