import React, { useEffect } from "react"
import Swiper from "swiper"
import "swiper/dist/css/swiper.min.css"

interface SlideshowProps {
  options?: Object
  children: JSX.Element[]
}

const Slideshow: React.FC<SlideshowProps> = ({ options, children }) => {
  useEffect(() => {
    new Swiper(".swiper-container", {
      ...options,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    })
  }, [])

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        {React.Children.map(children, (slide) => (
          React.cloneElement(slide as JSX.Element, {
            className: `swiper-slide ${slide.props.className || ''}`
          })
        ))}
      </div>

      <div className="swiper-button-next" />
      <div className="swiper-button-prev" />
    </div>
  )
}

export default Slideshow
