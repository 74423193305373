import React from "react"
import { graphql, Link } from "gatsby"
import DefaultLayout from "../layouts/DefaultLayout"
import { Wrapper, Seperator } from "../components/Layout"
import Image, { ImageProps } from "../components/Image"
import Slideshow from "../components/Slideshow"
import Befaring from "../components/Befaring"
// import palette from "../design/palette"

interface EmployeeProps {
  name: string
  title: string
  phoneNumber: string
  image: ImageProps
}

interface ProjectProps {
  title: string
  description: string
  url: string
  image: ImageProps
}

interface ServiceProps {
  title: string
  text: string
}

interface IndexPageTemplateProps {
  slideshow: ImageProps[]
  employees: EmployeeProps[]
  projects: ProjectProps[]
  services: ServiceProps[]
}

const IndexPageTemplate: React.FC<IndexPageTemplateProps> = ({
  slideshow,
  employees,
  projects,
  services
}) => {
  return (
    <main id="index-page">
      <section id="om-selskapet">
        <Slideshow
          options={{
            slidesPerView: 2,
            roundLengths: true,
            centeredSlides: true,
            initialSlide: 1,
            spaceBetween: 10
          }}
        >
          {slideshow.map((slide, index) => (
            <div
              key={index}
              style={{
                height: 400,
                backgroundImage: `url(${slide.src.childImageSharp.fluid.src})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            >
              {/* <Image
                src={slide.src}
                alt={slide.alt}
              /> */}
            </div>
          ))}
        </Slideshow>

        <Wrapper size="m" className="mx-auto text-center mt-8">
          <h1 className="text-5xl font-bold tracking-tighter">
            Bor du i en bygård som er brannfarlig, har høy fellesgjeld eller en bod på loftet du aldri bruker?
          </h1>

          <p className="my-8 px-12">
            YMRO kan hjelpe deg med å utnytte ditt loft. YMRO kjøper råloft og bygger det om til moderne loftsleiligheter. Dette fører til at hele bygården blir oppgradert. YMRO sin lang erfaring i bransjen og bred kompentanse gjør dem til en den beste samarbeidspartneren innenfor loft.
          </p>

          <Link
            to="/#kontakt-oss"
            className="bg-black text-white px-16 py-3 inline-block font-semibold shadow-2xl"
          >
            Book en befaring
          </Link>
        </Wrapper>

        <Wrapper
          size="xxl"
          className="mx-auto mt-24"
        >
          <Image
            className="w-10/12 mx-auto"
            src="uploads/loftbygging.jpg"
          />
        </Wrapper>
      </section>

      <section id="ansatte" className="mt-24 mb-32">
        <Wrapper
          size="l"
          className="mx-auto mb-16"
        >
          <Wrapper
            size="s"
            className="mx-auto text-center"
          >
            <Seperator className="bg-accent mx-auto mb-16" />

            <h2 className="text-3xl font-bold tracking-tight">
              Ymro er en erfaren entreprenør med spesialkompetanse på utvikling av råloft.
            </h2>
          </Wrapper>

          <p className="mt-12 cc-2">
            I forbindelse med utviklingen av råloft har vi mange års erfaring med vedlikeholdsarbeider på bygårder, noe som gjør bygården trygger og mer attraktiv og bo i. Dette kan være alt fra oppgradering av brannsikkerhet, (vedlikehold) fasader, trapperom og kjeller. Vi mener at oppskriften på gode prosjekter går igjennom god dialog mellom kunde og utvikler. Her har vi opparbeidet oss gode rutiner og jobber ofte sammen med styret/sameiet mot et felles mål og sluttresultat som alle kan være stolte av.
          </p>
        </Wrapper>

        <Wrapper
          size="xl"
          className="mx-auto flex"
          style={{
            height: "400px"
          }}
        >
          {employees.map((employee, index) => (
            <div
              key={index}
              className="flex-1 flex flex-col justify-end bg-cover"
              style={{
                backgroundImage: `url(${employee.image.src.childImageSharp.fluid.src})`
              }}
            >
              <div
                className="p-2 text-white text-center"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.66)" }}
              >
                <h3 className="font-bold mb-1">
                  {employee.name}
                </h3>
                <p className="text-sm leading-none -mb-1">
                  {employee.title}
                </p>
                <a
                  className="text-sm leading-none"
                  href={`tel://${employee.phoneNumber}`}
                >
                  {employee.phoneNumber}
                </a>
              </div>
            </div>
          ))}
        </Wrapper>
      </section>

      <section id="prosjekter">
        <Wrapper
          size="s"
          className="mx-auto text-center mb-24"
        >
          <Seperator className="bg-accent mx-auto mb-16" />

          <h2 className="text-3xl font-bold tracking-tight">
            YMRO sine prosjekter er unike, særpreget av utmerket kvalitet som holder tiden ut
          </h2>
        </Wrapper>

        <Wrapper
          size="xxl"
          className="mx-auto"
        >
          {projects.map((project, index) => (
            <div
              key={index}
              className={`w-full flex items-center ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
            >
              <div className="w-1/2">
                <Wrapper
                  size="xs"
                  className="mx-auto pr-6"
                >
                  <h3 className="text-2xl font-semibold">
                    {project.title}
                  </h3>

                  <Seperator className="bg-accent mt-4" />

                  <p className="my-8">
                    {project.description}
                  </p>

                  <a
                    href={project.url}
                    target="_blank"
                  >
                    Se mer >
                  </a>
                </Wrapper>
              </div>

              <Image
                className="w-1/2"
                src={project.image.src}
                alt={project.image.alt}
              />
            </div>
          ))}
        </Wrapper>
      </section>

      <Wrapper
        size="xl"
        className="mx-auto flex justify-between items-center shadow-2xl text-white bg-black my-32 py-8 px-16"
      >
        <h2 className="text-xl font-bold">
          Ser noe du liker? Vi undersøker gjerne muligheten for samarbeid.
        </h2>

        <Link
          to="/#kontakt-oss"
          className="bg-accent px-16 py-3 inline-block font-semibold"
        >
          Book befaring
        </Link>
      </Wrapper>

      <section id="tjenester" className="mt-32">
        <Wrapper
          size="s"
          className="mx-auto text-center"
        >
          <Seperator className="bg-accent mb-12 mx-auto" />
          <h2 className="text-3xl font-bold tracking-tight">
            Ymro tilbyr en smidig prosess fra A til Å, fra søknadsprosesser til sluttsalg.
          </h2>
        </Wrapper>

        <Wrapper
          size="l"
          className="mx-auto flex flex-wrap mt-24"
        >
          {services.map((service, index) => (
            <div
              key={index}
              className="w-1/3 px-4 mb-20"
            >
              <h3 className="text-2xl font-semibold mb-4">
                {service.title}
              </h3>
              <p>
                {service.text}
              </p>
            </div>
          ))}
        </Wrapper>
      </section>

      <section
        id="kontakt-oss"
        className="mt-24 mb-32"
      >
        <Wrapper
          size="s"
          className="mx-auto text-center"
        >
          <Seperator className="bg-accent mx-auto" />

          <h2 className="text-3xl font-bold tracking-tight my-12">
            Book en befaring hos Ymro allerede i dag, så kan vi undersøke mulighet for samarbeid.
          </h2>

          <p>
            Etter du har fylt ut noen detaljer om loftet ditt nedenfor vil en av våre kundebehandlere kontakte deg med et forslag om møtetid og med yttligere informasjon. Vi ses!
          </p>
        </Wrapper>

        <Wrapper
          size="l"
          className="mx-auto my-24"
        >
          <Befaring />
        </Wrapper>

        <Wrapper
          size="s"
          className="mx-auto text-center"
        >
          <p>
            Alternativt kan du også kontakte oss ved å sende en e-post til kontakt@ymro.no eller ringe oss på 401 22 307.
          </p>
        </Wrapper>
      </section>
    </main>
  )
}

interface IndexPageProps {
  data: {
    markdownRemark: {
      frontmatter: {
        slideshow: ImageProps[]
        employees: EmployeeProps[]
        projects: ProjectProps[]
        services: ServiceProps[]
      }
    }
  }
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <DefaultLayout>
      <IndexPageTemplate
        slideshow={frontmatter.slideshow}
        employees={frontmatter.employees}
        projects={frontmatter.projects}
        services={frontmatter.services}
      />
    </DefaultLayout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slideshow {
          src {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        employees {
          name
          title
          phoneNumber
          image {
            src {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        projects {
          title
          description
          url
          image {
            src {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        services {
          title
          text
        }
      }
    }
  }
`
