import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"
import palette from "../design/palette"
import Styles from "../design/styles"
import SEO from "../components/SEO"
import PageHeader from "../components/PageHeader"
import PageFooter from "../components/PageFooter"

const Page = styled.div`
  height: 100vh;
  overflow-y: scroll;
  padding-top: 105px;
`

interface DefaultLayoutProps {
  children: JSX.Element[] | JSX.Element
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => (
  <Page>
    <SEO />

    <Helmet>
      <html lang="no" />
      <meta name="theme-color" content={palette.accent} />
      <link rel="icon" type="image/png" href="/img/favicon.png" />
    </Helmet>

    <Styles />

    <PageHeader />

    {children}

    <PageFooter />
  </Page>
)

export default DefaultLayout
