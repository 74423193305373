import { createGlobalStyle } from "styled-components"
import palette from "./palette"
import "./resets.css"
import "./styles.css"

export default createGlobalStyle`
  body {
    color: ${palette.fg};
    background-color: ${palette.bg};
  }

  a {
    color: inherit;
    &:hover {
      // color: ${palette.accent};
      // border-bottom: 1px solid ${palette.accent};
    }
  }

  .text-accent {
    color: ${palette.accent};
  }

  .bg-accent {
    background-color: ${palette.accent};
  }
`
