import React from "react"
import styled from "styled-components"

export const Seperator = styled.hr`
  width: 60px;
  height: 2px;
`

interface WrapperProps {
  size?: string
  className?: string
  children: JSX.Element[] | JSX.Element
  style?: object
}

export const Wrapper: React.FC<WrapperProps> = ({
  size,
  className,
  children,
  style
}) => {
  let width = ""

  switch(size) {
    case "xxl":
      width = "1920px"
      break
    case "xl":
      width = "1200px"
      break
    case "l":
      width = "1000px"
      break
    case "m":
      width = "800px"
      break
    case "s":
      width = "550px"
      break
    case "xs":
      width = "350px"
      break
    default:
      width = "1200px"
  }

  const Elem = styled.div`
    max-width: ${width};
  `

  return (
    <Elem className={className || ""} style={style}>
      {children}
    </Elem>
  )
}
